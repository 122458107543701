/* 下拉选择框 2019-09-17 */

.mdropDown {
  position: relative;
}
.mprovinceTitle {
  display: flex;
  margin-top: 0.2rem;
  align-items: center;
  width: 100%;
}
.mprovince,
.mprovince1 {
  width: 10%;
  color: #919090;
  position: absolute;
  font-size: 0.2rem;
}
.mprovince {
  top: 0;
  left: 0;
}
.mprovince1 {
  top: 0;
  right: 0;
}

.mdropDown_list {
  width: 70%;
  display: flex;
  font-size: 0.2rem;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  height: 0.28rem;
  margin: 0 auto;
  overflow: hidden;
}
.mdropDown_list_show {
  width: 70%;
  font-size: 0.2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  height: auto;
  flex-wrap: wrap;
  padding-top: 0;
  
}
.mprovinceTitle li{
  padding:0 .12rem;
  margin-bottom:.1rem;
}
.activeClass{
  color:  var(--color);
  font-weight: bolder;
}
.activeIndex{
  color: black;
  font-weight: normal;
}

.iconshows {
  transform: rotate(3.142rad);
}
.miconshows {
  width: 0.2rem;
  height: 0.2rem;
}
