
.mcomcerns_content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 .5rem;
    box-shadow:0.01 0.01 0 .05rem #ccc  
}
.mcomcerns_item {
    cursor: pointer;
    width: 21%;
    height: .4rem;
    line-height: .4rem;
    text-align: center;
    font-size: .22rem;
    color: var(--color);
    border: 1px solid var(--color);
    border-radius: .1rem;
    margin-top: .22rem;
  }

