.mfindLawyerAll {
  background-color: #f2f2f2;
}
/* 基本信息  */

.mfindLawyerInfop_content {
  display: flex;
  height: 2.6rem;
  margin: 0.4rem 0.5rem;
  background-color: #fff;
}
.mfindLawyerInfop_img > div {
  width: 2rem;
  height: 100%;
  background-size: cover !important;
}
.BaseInfo {
  padding: 0.2rem .15rem;
}
.mareaLawyer {
  font-size: 0.2rem;
  margin-top:.16rem;
}
.mareaLawyer span {
  font-weight: bold;
  font-size:.24rem;
}

/* 律师简介 */
.mfindLawyerInfoText {
  font-size: 0.2rem;
  margin-top:.12rem;
}

.lvshijianjie {
  margin-top: 0.5rem;
  padding:0 .5rem;
}

.mfindLawyerInfoTag{
    padding:0.4rem .5rem;
}
.mfindLawyerInfoTag_content {
  display: flex;
  margin-top:.16rem;
  align-items: center;
  flex-wrap: wrap;
}

.mfindLawyerInfoTag_item {
  padding: 0.06rem 0.12rem;
  background:  var(--color);
  color: #fff;
  font-size: 0.18rem;
  border-radius: 0.16rem;
  margin-bottom: 0.16rem;
  margin-right: 0.15rem;
}
.OnlineConsultation{
    background-color: var(--color);
    padding:.1rem .26rem;
    color: #fff;
    font-size:.2rem;
    display: block;
    margin:0 auto;
    margin-top:.4rem;
}