.mcollaborateBanner {
  background-image: url("../../assets/collaborate/collaborate-Wed_02.jpg");

  height: 3.5rem;
  background-size: 100% 100%;
}

/* 司法受理数量屡创新高 */

.mcollaStatistics {
  color:  var(--color);

  background-color: #f2f2f2;
  padding: 0 0.5rem;
  padding-top: 0.4rem;
}
.mcollaStatistics h2 {
  text-align: center;
  font-size: 0.26rem;
  margin-bottom: 0.18rem;
}
.mcollaStatistics p {
  font-size: 0.18rem;
  Text-indent: 2em
}
.mcollaStatistics ul {
  display: flex;
  justify-content: space-between;
  margin-top: 0.2rem;
}
.mcollaStatistics ul > li {
  background-color: var(--color);
  color: #fff;
  padding: 0.2rem 0.04rem;

  text-align: center;
}
.mcollaStatistics ul > li h3 {
  font-weight: normal;
  font-weight: 0.2rem;
  font-size: 0.24rem;
  margin-bottom: 0.1rem;
}

.mcollaStatistics_info {
  font-size: 0.2rem;
  font-weight: 400;
  color: var(--color);
  line-height: 0.36rem;
}
.mcollaStatisticMap {
  height: 4rem;
  display: flex;
  margin: 0.34rem 0;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}
.mmap {
  height: 3rem;
  width: 90%;
  background-image: url("../../assets//collaborate/collaborate-Wed_06.jpg");
  background-size: 100% 100%;
  padding: 0.5rem;
}

/* 已覆盖全国主要城市  hy 2019-09-15 */
.mcollContainerAll {
  padding: 0 0.5rem;
  color: var(--color);
  position: relative;
  background-color: #f2f2f2;
}
.mcollContainer {
  margin: 0 0.24rem;
  margin-bottom: 0.2rem;
}

.mcollaborateMap_mapImg {
  width: 100%;
}

.mcollaborate_conter {
  margin: 0 0.24rem;
  display: flex;
  color: var(--color);
  margin-bottom: 0.4rem;
}

.mcollaborateMap_Tagging {
  position: absolute;
  left: 0;
  bottom: 0;
  margin-top: 0.45rem;
  font-size: 0.18rem;
  display: flex;
  flex-direction: column;
  justify-content: left;
  padding: 0 0.5rem;
}
.mapCil {
  width: 0.4rem;
  height: 0.4rem;
  margin-right: 0.1rem;
}
.mapImg {
  left: 1.95rem;
  height: 0.48rem;
  width: 0.38rem;
  margin-right: 0.1rem;
}

.tagging-font {
  font-size: 0.18rem;
}

.mcollaborateMap_Tagging > p > span {
  line-height: 0.4rem;
}
.mcollsborate-font {
  margin-bottom: 0.3rem;
}
/* 中间大图 */
.collaborateMiddleImg {
  height: 1.9rem;
  background-size: 100% 100%;
  background-image: url("../../assets/collaborate/collaborate_16.jpg");
}

/* 加盟律证时代的九大优势  */
.mcollaborateJoinAdvantage_content_all {
  background-color: #f2f2f2;
  padding:0 0.5rem;
  padding-top: .4rem;
}
.mcollaborateJoinAdvantage_content {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.4rem;
  justify-content: space-between;
}
.mcollaborateJoinAdvantage_item {
  width: 44%;
  color: var(--color);
  background-color: #fff;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.54rem;
  padding: 0.2rem 0.1rem;
  box-shadow: 0 0 0.05rem #ccc;
}
.mcollaborateJoinAdvantage {
  display: flex;
  align-items: center;
}
.mcollaborateJoinAdvantage_item i {
  font-size: 0.5rem;
  margin-right: 0.1rem;
}
.mcollaborateJoinAdvantage_item h3 {
  font-size: 0.3rem;
}
.mcollaborateJoinAdvantage_item p {
  font-size: 0.2rem;
}
.mcallaborateArrowImg {
  display: flex;
  align-items: center;
}
/* 加入律师时代的六大支持   */
.mcollaborateSupportAll {
  padding: 0.4rem 0.5rem;
  background-color: #f2f2f2;
}
.mcollaborateSupport_content {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.4rem;
  justify-content: space-between;
}

.mcollaborateSupport_item {
  width: 45%;
  background-color: #fff;
  margin-bottom: 0.2rem;
  padding: 0.1rem;
  box-shadow: 0 0 0.05rem #ccc;
}

.supportFont {
  color: var(--color);
  font-size: 0.28rem;
  margin-bottom: 0.2rem;
  font-weight: bold;
}
.supportText {
  font-size: 0.18rem;
  color: var(--color);
}

/* 加盟流程 */
.mcollaborateProcessAll {
  padding: 0 0.5rem;
  background-color: #f2f2f2;
}
.mcollaborate-ul {
  font-size: 0.26rem;
  margin-top: .3rem;
  padding: 0.2rem 0.3rem;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
  justify-content: space-between;
}
.mcollaborate-ul > li {
  text-align: center;
  position: relative;
  width: 40%;
  margin-bottom: 0.6rem;
}
.mcollaborate-ul > li h4 {
  position: absolute;
  font-size: 0.26rem;
  top: 0;
  left: 0.1rem;
}
.mNumberOne{
  color: #ccc;
}
.mNumTwo{
  color: var(--color);
}
.mcollaborate-ul > li p{
  color: var(--color);
}
.mcollaborate-ul > li img {
  width: 100%;
  box-shadow: 0 0 0.05rem;
}
.marrowTip,.marrowTipBottom,.marrowTipLeft {
  position: absolute;
  width: 0.4rem;
  height: 0.4rem; 
}
.marrowTip{
  top: 16%;
  left: 47%;
}
.marrowTipBottom {
  top:40%;
  left:72%;
}
.marrowTipLeft{
  top:64%;
  left:47%; 
}
.marrowTip > img,
.marrowTipBottom > img,
.marrowTipLeft>img
 {
  width: 100%;
  height: 100%;
}
.marrowTipBottom > img {
  transform: rotate(90deg);
}
.marrowTipLeft>img{
  transform: rotate(180deg);
}

/* 提交表单 19-09-17 wl */
.molawyerMenuContiner {
  padding: 0 0.5rem;
  background-color: #f2f2f2;
  padding-top: .3rem;
}
.molawyerMenuContinerTitle {
  display: flex;
  justify-content: space-between;
}
.molawyerMenuContinerTitleRight {
  font-size: 0.3rem;
  color: var(--color);
  text-align: right;
  padding-top: 0.1rem;
}
.molawyerMenuContinerTitleRight p:first-child {
  font-size: 0.2rem;
}

.moformChooseContiner {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.moformChooseContiner > div {
  color: var(--color);
  font-size: 0.22rem;
  width: 80%;
  margin: 0.2rem 0;
}
.moformChooseContiner input {
  padding: 0.18rem 0.14rem;
  width: 100%;
  font-size: 0.16rem;
  margin-top: 0.1rem;
  box-shadow: 0 0 0.05rem #ccc;
}

.mosubmit-btn {
  width: 100%;
  display: block;
  text-align: center;
  background-color: var(--color);
  color: white;
  font-size: 0.2rem;
  padding-top: 0.16rem;
  padding-bottom: 0.16rem;
  margin-bottom: 0.16rem;
  margin-top: 0.4rem;
}
.mhideText {
  font-size: 0.22rem;
  transform: translateY(0.32rem);
  color: var(--color);
  margin-bottom: 0.3rem;
}
.mtipText {
  font-size: 0.18rem;
  color: var(--color);
  text-align: center;
  margin-bottom: 0.44rem;
}
