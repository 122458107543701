
.maboutAll{
  background-color:#f2f2f2;
}
.maboutUsContainer {
  width: 100%;
  margin: auto;
}
/* banner*/
.mAboutBigImg {
  background-image: url("../../assets/aboutUs/aboutUs_02.jpg");
  background-size: 100% 100%;
  height: 3.3rem;
} 
/*  关于律政时代*/
 .mAboutAll {
  padding: 0 0.5rem;
  margin-top: 0.5rem;
}
.maboutUsLzsd {
  margin-top: 0.4rem;
  margin-bottom: 0.5rem;
} 
/* 左侧 */
 .m_about_li {
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: var(--color);
  font-size: 0.24rem;
  height: 2rem;
  margin-top: 0.2rem;
}
.maboutText {
  width: 46%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.maboutText h3 {
  margin-bottom: 0.1rem;
}
.maboutTextInfo {
  font-size: 0.18rem;
} 
/* 右侧 */
.aboutSmallImg {
  width: 54%;
  display: flex;
  align-items: center;
}
.aboutSmallImg img {
  width: 100%;
  height: 90%;
} 

/*  用你现在的钱,投资未来 */
.maboutUsAdvertisement {
  width: 100%;
  background: url("../../assets/aboutUs/aboutUs_13.jpg") no-repeat center;
  background-size: 100% 100%;
  height: 2.6rem;
} 

/*我们能做什么  */
.maboutUsDoing_content_all {
  padding: 0 0.5rem;
  margin-top: 0.5rem;
}
.maboutUsDoing_content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 0.4rem;
}

.maboutUsDoing_item {
  width: 45%;
  font-size: 0.26rem;
  color:  var(--color);
  justify-content: space-between;
  margin-bottom: 0.42rem;
} 
/* img start-- */
 .maboutImg {
  width: 100%;
}
.maboutImg img {
  width: 100%;
} 
/* img --end */
 .maboutUsDoing_item h4 {
  margin: 0.12rem 0;
}
.maboutUsDoing_item p {
  font-size: 0.18rem;
} 

/* 律政时代团队风采 hy 2019-09-16 */

.maboutUsTeam_left {
  padding: 0 0.5rem;
  color: #fff;
  display: flex;
  align-items: center;
  background-size: 100% 100%;
  background-image: url("../../assets/aboutUs/aboutUs_25.jpg");
}
.maboutUsTeam_left>ul{
  height: 3rem; 
  width: 100%;
  padding:.2rem 0;
  display: flex;
  justify-content: space-between;
  
}
.maboutUsTeam_left_img{
  width: 54%;
  display: flex;
  justify-content: space-between;
}
.maboutUsTeam_left_img>img{
  width: 49%;
}
.maboutUsTeam_right_text{
  width: 36%;
  font-size: .3rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.maboutUsTeam_right_text p{
  font-size:.24rem;
}
.maboutUsTeam_right_text h3{
  margin-bottom:.3rem;
}
.maboutUsTeam_right_text img{
  width: 0.4rem;
  height: 0.4rem;
}
.marrow-left{

  margin-right:.1rem ;
}

/* 一个促进共享律师发展的平台 */

.maboutPositionAll {
  width: 100%;
  background: url("https://lzsd.oss-cn-shenzhen.aliyuncs.com/www.lzsd/aboutUs/img.png")
    no-repeat;
  background-size:100% 100%; 
  margin-top: .4rem;
  padding-bottom:.24rem;
  margin-bottom: .5rem;
}
.maboutUsPositionTitle{
  font-size: .26rem;
  color:  var(--color);
  display: flex;
  justify-content: space-between;
  background-color: #f2f4fc;
  padding: .1rem .5rem;
  box-shadow:0 .01rem 0 0rem #ccc;
}
.mpositionTitleRight{
  text-align: right;
}
.mpositionTitleLeftEn{
  font-size: .2rem;
}
.mpositionTitleLeft{
  font-size: .26rem;
}
.mpositionTitleRight{
  font-size: .26rem;
}

.maboutUsTitle {
  width: 100%;
  padding-bottom: 0.22rem;
  background: url("https://lzsd.oss-cn-shenzhen.aliyuncs.com/www.lzsd/aboutUs/title_banner.png")
    no-repeat center;
  background-size: cover;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: #fff;
}
.maboutUsTitle > h1 {
  font-size: 0.22rem;
  letter-spacing: 0.04rem;
  margin-top: 0.18rem;
}
.maboutUsTitle > p {
  font-size: 0.16rem;
  margin-top: 0.12rem;
} 
/* 咨询超级合伙人项目 2019-9-16 */
.maboutUsPositionform {
  padding:0 .5rem;
  margin: 0 0.24rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin:0 auto;
  margin-bottom: 0.48rem;
}
.maboutUsPositionform_item {
  height: 0.5rem;
  width: 100%;
  margin-top: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.maboutUsPositionform_item input {
  height:100%;
  width: 80%;
  padding-left:.1rem;
  box-shadow:0 0 .05rem #ccc;
}
.maboutUsPositionform_btn {
  text-align: center;
  width: 100%;
  margin-top:.3rem;
}
.maboutUsPositionform_btn > button {
  padding: 0.12rem ;
  background:  var(--color);
  color: #fff;
  font-size: 0.24rem;
  width: 80%;

} 

.maboutUsPositionform_title {
  padding: 0.12rem;
  background: var(--color);
  width: 80%;
  color: #fff;
  display: flex;
  justify-content: center;
  transform: translate3d(0, -182%, 0);
  opacity: 0;
  transition: transform 0.3s;
}
.maboutUstitle_warning {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}
.maboutUsPositionform_title p {
  font-size: 0.24rem;
  margin: 0;
} 
