.footerContiner {
  background-color: var(--color);
  padding: 30px 0 20px 0;
}
.footerWorkContiner {
  width: var(--size);
  margin: 0 auto;
  padding: 0 var(--padding);
}
.footTop {
  display: flex;
  padding-bottom: 20px;
  margin-bottom: 40px;
}
.footerUl p {
  font-size: 22px;
  margin-bottom: 18px;
}
.footerUl > li > .companyCnGoa {
  font-size: 22px;
  margin-bottom: 10px;
}

.icon-icon-test23 {
  margin-right: 10px;
}

.footerUl {
  display: flex;
  color: white;
  justify-content: space-between;
  margin-top: 40px;
  flex: 1;
}
.footerUl li {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.footerUl span {
  font-size: 12px;
  cursor: pointer;
  line-height: 2;
}
.footerUl > li .companyEnGoal {
  font-size: 18px;
}
.footerUlContiner {
  display: flex;
}
.companyCopy {
  text-align: center;
  font-size: 10px;
  color: #fafafa;
}
.advContiner {
  margin-left: 72px;
}

/* 公司简介 */
.advContiner {
  height: 150px;
  width: var(--size);
  margin: 0 auto;
  color: var(--color);
  padding: 0 var(--padding);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.advContiner > h1 {
  font-size: 30px;
}
.advContiner > p {
  font-size: 28px;
  position: relative;
}
.advContiner > p > img {
  position: absolute;
  width: 40px;
}
