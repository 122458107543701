.mbuyProductWorkContiner {
  background-color: #f2f2f2;
}

.mproductDetailImg {
  width: 100%;
  height: 3rem;
  margin-bottom: 0.14rem;
}
.mproductDetailImg > img {
  width: 100%;
  height: 100%;
}

.mbuyImgFont {
  padding: 0 0.5rem;
  margin-bottom: 0.36rem;
}
.mbuyImgFont > p:first-child {
  font-size: 0.14rem;
  color: #ccc;
  margin-bottom: 0.24rem;
}
.mbuyImgFont h3 {
  color:  var(--color);
  
}
.mBuyUl {
  margin-top: 0.2rem;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
}
.mBuyUl > li {
  font-size: 0.2rem;
  color: #fff;
  display: flex;
  width: 30%;
  margin-bottom: 0.15rem;
  padding: 0.14rem 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color:  var(--color);
}
.mBuyLastLi {
  opacity: 0;
}
.mStarBox > img {
  width: 0.2rem;
  height: 0.2rem;
}
.mBuyBtn {
  display: flex;
  align-items: center;
  justify-content: center;
}
.mBuyBtn > button {
  font-size: 0.2rem;
  padding: 0.12rem 0.3rem;
  border: 1px solid  var(--color);
  display: inline-block;
  height: 100%;
  color:  var(--color);
  margin-right: 0.12rem;
}

/*已服务 已咨询 好评率 */
.mBuyHorizonalStripe {
  display: flex;
  color: #fff;
  background-color:  var(--color);
  justify-content: space-between;
  align-items: center;
  font-size: 0.2rem;
  padding: 0.2rem 0.14rem;
  margin: 0.24rem 0.5rem;
  margin-bottom: 0.12rem;
}
/* 提示 */
.mBuyTip {
  font-size: 0.16rem;
  color:  var(--color);
  text-align: center;
  margin-bottom: 0.24rem;
}

/* 快速办理 材料免费寄 流程掌控 */
.mBuyChecked {
  display: flex;
  justify-content: space-between;
  padding: 0 0.5rem;
  margin-top: 0.2rem;
  margin-bottom: 0.4rem;
  font-size: 0.28rem;
  color:  var(--color);
}
.mBuyChecked > li {
  display: flex;
  align-items: center;
  justify-content: center;
}
.mBuyChecked > li img {
  width: 0.34rem;
  height: 0.34rem;
  margin-right: 0.12rem;
}
/* 产品优势 */
.madvantageousproduct {
  margin: 0 0.5rem;
}

/* 共享律师的优势有哪些 */
.madvantageEles {
  font-size: 0.24rem;
  color:  var(--color);
  margin: 0.2rem 0;
  border-bottom: 1px solid  var(--color);
}
.madvantageEles span {
  font-weight: bold;
  margin-left: 0.18rem;
}
.buyVs {
  text-align: center;
  display: flex;

  margin-bottom: 0.4rem;
}

.buyVs > li > h4 {
  line-height: 0.45rem;
  font-size: 0.24rem;
  color: white;
  background-color:  var(--color);
  border-bottom: 0.04rem solid white;
}

.buyVs > li > p {
  font-size: 0.18rem;
  background-color: #e2e6ff;
}

.buyVs > li:nth-child(2) {
  color:  var(--color);
  font-weight: bold;
  border-left: 0.04rem solid white;
  border-right: 0.04rem solid white;
}

.buyVs > li > p:not(:nth-of-type(6)) {
  display: flex;
  align-items: center;
  height: 1.4rem;
  justify-content: center;
  border-bottom: 0.04rem solid white;
}

/* 用心服务 专属一对一 */

.buyImg {
  display: flex;
  justify-content: space-between;
}

.buyImg > li {
  text-align: center;
  width: 30%;
  margin: auto;
  font-size: 0;
  margin-bottom: 0.1rem;
  background-color: #f6f9fb;
}

.buyImg > li > img {
  width: 100%;
  height: 2rem;
}

.buyImg > li > h5 {
  font-size: 0.26rem;
  color: white;
  background-color:  var(--color);
  padding: 0.1rem 0;
  letter-spacing: 0.04rem;
}

.buyImg > li > p {
  font-size: 0.22rem;
  color: #afb2b4;
  background-color: #f6f9fb;
  padding: 0.06rem 0;
}

.buyImg > li > p:nth-of-type(1) {
  padding-top: 0.3rem;
}

/* 产品优势 */

.buyAdvantage {
  font-size: 0.24rem;
  color:  var(--color);
  width: 47%;
  padding-bottom: 0.1rem;
  border-bottom: 0.04rem solid var(--color);
  margin-top: 0.44rem;
  margin-bottom: 0.4rem;
}
.buyAdvantage span {
  font-weight: bold;
}
.buyAdvantage > div:nth-of-type(2) {
  width: 39%;
}

.buyAdvantage > div > span {
  font-weight: bold;
  margin-left: 0.18rem;
}


/* 服务介绍 */
.mServiceIntroduction {
  margin: 0 0.5rem;
}

/* 遇到这些问题 怎么办？ */
.mproductImg {
  width: 100%;
}
.mproductImg > li {
  color:  var(--color);
  display: flex;
  width:90%;
  background-color: #fff;
  margin-bottom: 0.42rem;
  border-radius: 0.3rem;
  padding-bottom: 0.2rem;
}
.mproductImg > li:nth-child(even) {
  justify-content: flex-end;
  text-align: right;
  margin-left: .5rem;
}
.mproductImg > li:nth-of-type(4) > div > i {
  font-size: 0.8rem;
}

.mproductImg > li > div > i {
  color:  var(--color);
  font-size: 1rem;
  margin-right: 0.18rem;
  margin-left: 0.1rem;
}

.mproductImg > li > div > h2 {
  font-size: 0.25rem;
  font-weight: bold;
  margin-bottom: 0.12rem;
  margin-top: 0.1rem;
}

.mproductImg > li > div > p {
  font-size: 0.2rem;
}
/* 婚姻家庭 债权债务... */
.incoSevice{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    
}
.incoSevice>li{
    width: 21%;
    display: flex;
    margin-bottom: .3rem;
    flex-direction: column;
   justify-content: space-between;
    align-items: center;
  
}
.incoSevice i {
    font-size: 0.9rem;
    color: var(--color);
  }
  .incoSevice > li:nth-child(7) i {
    font-size: 0.7rem;
  }
  .incoStyle > p {
    font-size: 0.2rem;
    font-weight: bold;
    color:  var(--color);
  }
  /* 服务流程 */
.serviceProcess{
  margin:0 .5rem;
  margin-top: .2rem;
}
/*我们承诺  售后服务 */
.buyPromise {
  margin-bottom: 0.34rem;
}

.buyPromise > li {
  display: flex;
  margin-bottom: 0.62rem;
}
.iconfontBox{
 position: relative;
 width: 10%;
}
.buyPromise > li i {
  font-size: .4rem;
  color:  var(--color);
  margin-right: 0.24rem;
  position: absolute;
  
}

.buyPromise h5 {
  font-size: 0.24rem;
  color:  var(--color);
  margin-bottom: 0.12rem;
}

.buyPromise p {
  font-size: 0.18rem;
  color:  var(--color);
}

.flowChart img {
  display: block;
  width: 100%;
  height: 0.88rem;
}

.flowChart > li > div {
  width: 80%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  margin-top: 0.2rem;
  margin-bottom: 0.48rem;
}

.flowChart > li > div > p {
  font-size: 0.16rem;
  color: #a2a2a2;
  width: 30%;
  text-align: center;
}
.flowChart > li > div > div > p {
  font-size: 0.16rem;
  color: #a2a2a2;
  text-align: center;
  width: 100%;
}
.flowChart > li > div > div {
  width: 26%;
}