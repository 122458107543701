.mpagination {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.18rem;
  text-align: center;
  margin: 0.3rem 0.24rem;
  margin-bottom: 0.3rem;
}
.mpagination>li{
  margin-right: .12rem;
}
.mpagination_nomore,.mpagination_morePage {
  background: #4b5a98;
  color: #fff;
  cursor: pointer;
  height: 0.5rem;
  width: 1.4rem;
  line-height: 0.5rem;
  margin-bottom: 0.4rem;
}
.mpagination_nomore{
  background: #9c9c9c;
}

.mpages {
  cursor: pointer;
  width: 0.5rem;
  height: 0.5rem;
  line-height: 0.5rem;
  background: #fff;
  color: #4b5a98;
  transition: all 0.2s;
}
.mactivePage,
.mpagination_hovers:hover {
  background: #4b5a98;
  color: #fff;
}
.mlawyerIcon {
  position: relative;
  width: 4%;
}
.mlawyerIcon > span > i {
  position: absolute;
  color: #4b5a98;
  top: 0.5rem;
  right: 0rem;
}
