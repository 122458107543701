/* 标题样式 */

.mtitle_ {
    margin: 0.3rem 0 0.2rem;
    text-align: center;
    letter-spacing: 0.01rem;
    font-weight: bold;
}

.mtitle_ h3 {
    display: flex;
    justify-content: center;
    font-size: 0.3rem;
    color: #4E5EA1;
    margin-bottom: 0.12rem;
}

.mtitle_ p {
    font-size: 0.14rem;
    color: rgba(0, 0, 0, .3);
}
