.mselectCity {
  padding: 0rem 0.5rem;
  padding-top: 0.3rem;
}
.breadLi {
  border: 1px solid;
  color: var(--color);
  margin-left: 0.1rem;
  padding: 0.02rem 0.1rem;
}
.breadLi i {
  display: inline-block;
  color: #ccc;
  margin-left: 0.1rem;
}
.searchStringUl {
  display: flex;
  width: 70%;
  align-items: center;
}

.searchString {
  font-size: 0.2rem;
  color: #ccc;
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.btnCity{
  color: #fff;
  display: block;
  font-size: 0.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width:20%;
  padding: 0.1rem;
  background-color: #4b5a98;
}
.btnCity > img {
  width: 0.2rem;
  height: 0.2rem;
}
.searchCondition{
  width: 20%;
}
