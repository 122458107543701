 * {
  margin: 0;
  padding: 0;
}
.mfooterContiner {
  background-color: #4b5a98;
  padding-top: 0.32rem;
 
}
.footerFirst {
  display: flex;
  flex-direction: column;
  width: 100%;
  color: white;
  align-items: center;
}
.footerPhone,.footerPhoneNumber,.footer-address {
  margin-bottom:.12rem;
}
.footerPhone,
.footer-address {
  font-size: 0.3rem;
}
.footerPhoneNumber,
.footerAddress {
  font-size: 0.3rem;
  font-weight: bold;
}
.footerAddress {
  font-size: 0.3rem;
}

.footImg {
  display: flex;
  flex-direction: column;
  width: 6.12rem;
  margin: auto;
}

.footText {
  margin:0 .2rem;
  margin-top: 0.2rem;
  font-size: 0.3rem;
  color: white;
  text-align: center;
  border-bottom: 2px solid white;
  
  padding-bottom: 0.4rem;
}

.footText > p:nth-of-type(1) {
  margin-bottom: 0.2rem;
}
.footText > p:nth-of-type(2) {
  font-size: 0.2rem;
}
.footAdress {
  margin-top: 0.28rem;
  padding: 0.2rem;
  text-align: center;
  color: white;
  font-size: 0.1rem;
}
.addressGxb{
  color: #fff;
  text-decoration: none;
} 