.entryAll {
  background-color: #f2f2f2;

  // 大图
  .entryBigImg {
    height: 3.3rem;
    background-image: url("../../assets/m_entry/banner.jpg");
    background-size: 100% 100%;
  }
  .tabchange-box {

    // 导航栏
    ul {
      display: flex;
      color: var(--color);
      font-size: 0.26rem;
      justify-content: space-between;
      border-bottom: 1px solid #eee;
      flex-direction: row;
      padding: 0.1rem 0.5rem;
      margin-bottom: .3rem;
      background-color: #fff;

      li {
        text-align: center;
        padding: 0.1rem 0.2rem;
      }

      .active {
        border-radius: 0.26rem;
        background-color: var(--color);
        color: #fff;
      }
    }

    // 公示栏
    .firstEntryInfo {
      font-size: 0.28rem;
      text-align: center;
      color: var(--color);
      margin-bottom: 0.8rem;

      .entyrBoard {
        background-color: #fff;
        height: 5rem;
        margin: 0.2rem 0.5rem;
        box-shadow: 0 0 0.05rem #ccc;
        margin-top: .3rem;

        .dv-scroll-board> {
          .header {
            height: 10% !important;
          }

          .rows {
            height: 90% !important;

            .row-item {
              font-size: .2rem !important;
              height: .64rem !important;
              line-height: .64rem !important;
            }

          }

        }

      }
    }

    /* 服务优势 */
    .mentryServiceContiner {
      margin: .3rem .5rem;

      .mentryServiceUl {
        color: var(--color);
        font-size: .2rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        li {
          width: 32%;
          padding: 0.1rem;
          text-align: center;
          background-color: #fff;
          padding-top: 0.18rem;
          box-sizing: border-box;
          margin-bottom: 0.4rem;
          display: flex;
          justify-content: space-between;

          div:first-child {
            display: flex;
            flex-direction: column;
          }

          div {
            .iconfont {
              font-size: 0.5rem;
              width: 0.54rem;
              height: 0.54rem;
              margin-bottom: 0.1rem;
            }

            span {
              font-size: 0.16rem;
            }

          }

          .mserviceHover {
            color: var(--color);
            font-size: 0.16rem;
            margin-left: 0.16rem;
          }

        }

      }
      .entryTogther {
        text-align: center;
        color: var(--color);
        padding: 0 0.5rem;

        h2 {
          font-size: 0.9rem;
          margin-bottom: 0.2rem;
        }

        p {
          font-size: 0.28rem;
          margin-bottom: 0.15rem;
        }
      }

    }

    // 产品服务
    .entryProductService {
      font-size: 0.3rem;
      color: var(--color);
      margin-bottom: 0.9rem;

      ul {
        flex-direction: column;
        padding: 0.3rem 0.5rem;
        background-color: #f2f2f2;
        position: relative;   
        .borerline{
          border-bottom: 2px solid var(--color);
          margin:0 .2rem;
          position: absolute;
          width: 80%;
          top: 50%;
          left: 8%;
         
        }
        li {
          background-color: #fff;
          padding: 0.3rem ;

          p {
            font-size: 0.18rem;
            margin-top: 0.2rem;
            padding-left: 0.3rem;
          }

          h3 {
            span {
              display: inline-block;
              width: 0.2rem;
              height: 0.2rem;
              margin-right: 0.1rem;
              background-color: var(--color);
              border-radius: 50%;
            }
          }
        }
      }

      .entryMode {
        text-align: center;
        h2{
          font-size: .9rem;
          margin-bottom: .2rem;
        }
      }
    }

    // 服务领域
    .entryPracticeAreas {
      font-size: 0.28rem;
      color: var(--color);
      margin-bottom: 0.9rem;

      ul {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        text-align: center;
        background-color: #f2f2f2;
        margin-top: .2rem;

        li {
          width: 30%;
          background-color: var(--color);
          color: #fff;
          margin-bottom: 0.2rem;
          padding: 0.2rem 0;

          h3 {
            font-weight: normal;
            margin-bottom: 0.16rem;
          }

          p {
            font-size: 0.18rem;
          }

        }
      }

      .entryTogther {
        text-align: center;
        color: var(--color);
        padding: 0 0.5rem;

        h2 {
          font-size: 0.9rem;
          margin-bottom: 0.2rem;
        }

        p {
          font-size: 0.28rem;
          margin-bottom: 0.15rem;
        }
      }
    }

  }
}









