.mfindLawyerContainer_lll {
  background-color: #f2f2f2;
}
.mfindLawyerContainer_l {
  background-color: #fff;
}
.mfindLawyerTitle {
  background: #f6f6f6;
  padding: 0.15rem 0.24rem;
  color: #878787;
  font-size: 0.12rem;
  margin: 0.12rem 0.24rem;
}
/* 加载中 */
.dotloading {
  display: flex;
  justify-content: center;
}

/* 律师列表*/

.mfindLawyerItem_content {
  padding-bottom: 0.4rem;
  border-radius: 0.2rem;
  background-color: #fff;
  padding: 0 0.5rem;
  box-shadow: 0 0 0.05rem #ccc;
}

.mfindLawyerItem_ {
  display: flex;
  align-items: center;
  padding: 0.16rem 0;
  color: #4d4d4d;
}

.mfindLawyerItem_ > section {
  display: flex;
  align-items: center;
}

.mfindLawyerItem_ > section > .mimgs {
  min-width: 1.22rem;
  height: 1.35rem;
  background-size: cover !important;
  display: block;
  border-radius: 0.08rem;
  margin-right: 0.18rem;
  transition: all 0.3s;
}
.mfindLawyerItem_info {
  width: 80%;
  display: flex;
  justify-content: space-between;
}
.mfindLawyerItem_info-left {
  width: 70%;
}
.mfindLawyerItem_info-right {
  width: 30%;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.mfindLawyerItem_info > div > h3 {
  margin-bottom: 0.1rem;
  font-size: 0.18rem;
}

.mfindLawyerItem_info > div > p {
  font-size: 0.12rem;
  margin-bottom: 0.03rem;
}

.mfindLawyerItem_info > div > div {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 0.1rem;
}

.mfindLawyerItem_info > div > div > span {
  min-width: 1rem;
  text-align: center;
  line-height: 0.22rem;
  font-size: 0.12rem;
  color:  var(--color);
  border: 1px solid  var(--color);
  border-radius: 0.12rem;
  margin-bottom: 0.1rem;
  padding: 0.02rem 0.04rem;
}
.mfindLawyerItem_info div span:not(:last-child) {
  margin-right: 0.12rem;
}
.mfindLawyerItem_btn {
  font-size: 0.1rem;
  background-color:  var(--color);
  color: white;
  text-decoration: none;
  text-align: center;
  border-radius: 0.1rem;
  width: 1rem;
  padding: 0.06rem 0.1rem;
}

.mfindLawyerItem_btn:hover {
  background:  var(--color);
  box-shadow: 1px 0.04rem 0.06rem #ccc;
}

/* 帅选条件 */
.mfindLawyerItem_content_all {
  background-color: #f2f2f2;
  margin-bottom: 0.4rem;
}
.mfindLawyerFindFn_item {
  width: 100%;
  padding-bottom: 0.18rem;
  font-size: 0.14rem;
}
.mselectfindLawyerItem {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.mdropDown_listat {
  display: flex;
  margin-top: 0.2rem;
  justify-content: space-between;
  padding: 0 0.5rem;
  font-size: 0.2rem;
}
.mdropDown_list_showat {
  color:  var(--color);
  font-weight: bold;
}

/* 结果排序 */
.mresultSortT {
  margin: 0.3rem 0.5rem;
}
.mresultSort {
  color: #4b5a98;
  font-size: 0.2rem;
  padding-left: 0.1rem;
  border-left: 0.04rem solid #4b5a98;
}
.mfindLawyerBtn {
  background-color: #4b5a98;
  color: #fff;
  display: inline-block;
  padding: 0.14rem 0.2rem;
  width: 20%;
  margin-left: 40%;
  margin-bottom: 0.3rem;
}
