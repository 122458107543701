.box-info {
  padding: 0 0.5rem;
  padding-top: 0.3rem;
  background-color: #f2f2f2;
}
.companyLawyerBigImg {
  height: 3.3rem;
  background-image: url("../../assets/companyLawyer/companyLawyer-Wed_02.jpg");
  background-size: 100% 100%;
} 

/*DataList*/
.data_list {
  color: var(--color);
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  font-size: 0.18rem;
  margin-top: 0.2rem;
}
.data_list .box {
  width: 25%;
}

.box-item {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-top: 0.28rem;
} 
.data_list_num {
  font-size: 0.34rem;
  font-weight: bolder;
}
.data_list_unit {
  font-size: 0.14rem;
  width: 0.2rem;
  height: 0.2rem;
  color: #fff;
  line-height: 0.2rem;
  border-radius: 50%;
  text-align: center;
  background-color: var(--color);
  position: absolute;
  left: 64%;
  bottom: 0;
} 

/*Chioce*/
.chioce {
  display: flex;
  align-items: center;
  font-size: 0.2rem;
  justify-content: space-around;
}
.chioce .box {
  width: 49%;
  color: var(--color);
  display: flex;
  justify-content: center;
}
.line {
  width: 3px;
  height: 0.6rem;
  background: #ededed;
} 
.chioce .box > .iconfont {
  font-size: 0.6rem;
  justify-content: center;
  padding: 0.2rem 0.1rem;
}
.chioce .box .rightBox .right-box-title {
  font-size: 0.34rem;
  font-weight: 800;
}
.chioce .box .rightBox .right-box-content {
  font-size: 0.2rem;
  font-weight: 400;
  margin-top: 0.1rem;
} 
/*SeriveCard*/
 .m_seriveCard_all {
  padding: 0 0.5rem;
  background-color: #f0f0f0;
  padding-top: 0.5rem;
}
.serive {
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  margin-top: 0.2rem;
  height: 3rem;
  background-color: #fff;
  justify-content: space-between;
} 

.serive > li {
  width: 45%;
  margin: 0.15rem 0rem;
  padding: 0 0.1rem;
}
.serive_card > p {
  font-size: 0.26rem;
  color:  var(--color);
  font-weight: bold;
  margin-bottom: 0.1rem;
}
.serive .serive_content {
  padding-bottom: 0.14rem;
  bottom: 0.06rem;
  font-size: 0.18rem;
  color:  var(--color);
} 

/*seriveInfo*/
 .m_service_all {
  background-image: url("../../assets/customerLawyer/customerLawyer-Wed_07.jpg");
  background-size: 100% 100%;
  padding: 0.5rem 0.5rem;
} 
/* title start */
.m_service_title {
  margin: 0.2rem 0;
}
.m_service_title h3 {
  font-size: 0.3rem;
  font-weight: bold;
  color: #fff;
}
.m_service_title p {
  font-size: 0.3rem;
  color: #fff;
} 
/* title --end */

 .info {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  color: #fff;
  padding-bottom: 0.2rem;
}
.info .info_box {
  width: 33%;
  box-sizing: border-box;
}
.info .info_box_title {
  font-weight: bolder;
  font-size: 0.22rem;
  padding: 0.2rem 0;
  width: 90%;
  border-bottom: 2px solid #fff;
} 

/*seriveCase*/
 .m_seriveCase_all {
  padding: 0 0.5rem;
  padding: 0.5rem;
  background-color: #f0f0f0;
}
.case {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  font-size: 0.2rem;
  color:  var(--color);
  margin-top:.4rem;
}
.caseLi {
  width: 46%;
  height: 4rem;
  margin-bottom: 0.3rem;
  border-radius: 0.2rem;
  box-shadow: 0 0 0.1rem #a1a1a1;
  margin-bottom:.3rem;
} 
.case-direction {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.case-direction h3{
    margin-bottom:.1rem;
}
.case-direction>div:nth-child(1){
   padding:.2rem;
} 
/* 图片设置 start-- */
 .caseLiFirst {
    width: 100%;
    height: 100%;
}
.mCompanyImg4{
    width: 100%;
    height: 50%;
}
.mCompanyImg4>img{
    width: 100%;
    height: 100%;
} 
/* 图片设置 --end */

/* 提交表单 */
.flawyerMenuContiner {

  margin-top: 1.16rem;
}
.fformContiner {
  width: 100%;
  margin-top: 0.54rem;
  display: flex;
  justify-content: space-between;
}

.fformBox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

.fformTip {
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.1rem;
}
.fformTipRight {
  text-align: right;
} 
 .fformTip p {
  font-size: 0.2rem;
  color: var(--color);
}
.fformTip h3 {
  font-size: 0.3rem;
  color: var(--color);
}

.fformChooseContiner {
  margin-top: 0.7rem;
  display: flex;
  flex-wrap: wrap;
  color: var(--color);
  justify-content: space-between;
}

.fformChooseContiner > div {
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 0.22rem;
  margin-bottom: 0.2rem;
} 

.fformChooseContiner input {
  border: 1px solid #ffffff;
  box-shadow: 0 0 0.05rem #ccc;
  padding: 0.12rem 0.1rem;
  font-size: 0.16rem;
  margin-top: 0.1rem;
}

.fsubmit-btn {
  width: 100%;
  display: block;
  text-align: center;
  background-color:  var(--color);
  color: white;
  font-size: 0.2rem;
  padding-top: 0.16rem;
  padding-bottom: 0.16rem;
  margin-top: 0.28rem;
}
.ffrom-tip {
  color:  var(--color);
  text-align: center;
  font-size: 0.16rem;
  margin-top: 0.1rem;
  margin-bottom: 0.48rem;
} 
 .prompting {
  font-size: 0.22rem;
  color: var(--color);
  transform: translateY(0.32rem);
  position: absolute;
  left: 0;
  bottom: 26%;
} 
