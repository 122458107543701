 .mheaderContiner {
  box-sizing: border-box;
  height: 0.92rem;
  background-color: #4b5a98;
  padding: 0 0.24rem;
}
.mheaderSearchContiner {
  padding: 0.2rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mheaderSearchContiner img {
  width: 1.24rem;
  height: 0.46rem;
  display: block;
}
.mheaderImgRight{
  opacity: 0;
}
.mheaderSearchContiner img:nth-of-type(1) {
  width: 0.38rem;
  height: 0.32rem;
}
.mheaderSearchContiner img:nth-of-type(3) {
  width: 0.36rem;
  height: 0.36rem;
} 
/* 文字设置 */
.mheaderNavUl {
  position: absolute;
  top: 0.7rem;
  z-index: 20;
  left: 0rem;
  padding-bottom: 0.1rem;
  background-color: #4b5a98;
  box-shadow: 0.1rem 0.1rem 0.1rem -0.12rem #333;
  margin-left: 0.2rem;
}
.mheaderNavUl > li {
  font-size: 0.24rem;
  text-align: center;
  padding: 0.2rem 0.3rem;
}
.mheaderNavUl > li:not(:last-child) {
  border-bottom: 1px solid white;
}
.mheaderLink {
  text-decoration: none;
  color: white;
} 
