body{
    margin: 0;
}
#root{
    height: 100%;
}
.appContiner{
    display: flex;
    flex-direction: column;
}
.browserRouter{
    flex: 1;
}
p, h1, h3, h4, h5{
    margin: 0;
}
.workContiner{
    width: 1200px;
    margin: 0 auto;
}
input{
    outline: none;
    padding: 0;
    border: none;
}
select{
    background-color: white;
    border: none;
    outline: none;
}
button{
    margin: 0;
    padding: 0;
    line-height: 1;
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;
}

ul{
    margin: 0;
    padding: 0;
}
li{
    list-style: none;
}

/* 中文标题 */
.entryServiceCn{
    text-align: center;
    font-size: 24px;
    color: #333;
    margin-bottom: 14px;
}
/* 英文标题 */
.entryServiceEn{
    text-align: center;
    font-size: 12px;
    color: #cacaca;
    letter-spacing: 6px;
}

