.mlawyerInfoContainer {
    width: 100%;
}
.mconsultInfocontent {
    margin-bottom: .4rem;
}

.mlawyerInfoContainer_content {
    display: flex;
    padding:0 .5rem;
}

.mconsultInfocontent_l {
    flex: 1;
}

.mconsultInfocontent_r {
    width: 30%;
    margin-left: .1rem;
}

/* 文章内容 */
.mconsultInfocontent_tit {
    text-align: center;
    font-size: .4rem;
    color: #333;
    margin-top: .2rem;
    margin-bottom: .3rem;
}
.mconsultInfocontent_content{
    font-size: .2rem;
}
.mconsultInfocontent_c {
    display: flex;
}
.mconsultInfocontent_content>div{
    font-size: .2rem;
    margin-bottom:.1rem;
}

.mconsultInfocontent_content>div:nth-of-type(1)>strong {
    font-size: .3rem;
}

.mconsultInfocontent_content img {
    display: block;
    height: auto;
    max-width: 100%;
    min-width: 100%;
    margin-top:.14rem;
}

.mconsultInfocontent_content h3 {
    font-size: .28rem;

}
.mconsultInfocontent_content>p{
    margin-bottom:.12rem;
}
.mconsultInfocontent_content>section> p {
    font-size: .2rem;
}
.mconsultInfocontent_content p {
    font-size: .2rem;
    margin-bottom:.14rem;
}
.mconsultInfocontent_content>section>section{
    font-size: .2rem;
}
.mconsultInfocontent_content>section>h1 {
    font-size: .2rem;
 

}
.mconsultInfocontent_content>section>section>section>section>h1 {
    font-size: .3rem;
    margin-bottom: .14rem;

}
.mconsultInfocontent_content>h2 {
    font-size: .4rem;
    line-height: .1rem;
}
.mconsultInfocontent_content>section>section>h2 {
    font-size: .3rem;
 
}
.mconsultInfocontent_content>section>section>section>h2 {
    font-size: .3rem;
 
}
.mconsultInfocontent_content>section>section>section>section>section>h2 {
    font-size: .3rem;
 
}
.mconsultInfocontent_content p span {
    font-size: .2rem;
    line-height:.1rem;
    letter-spacing:0;
}

.mconsultInfocontent_content>h2>span {
    font-size: .2rem;
    line-height: .2rem;
}
.mconsultInfocontent_content>h2>strong {
    font-size: .3rem;
  
}
.mconsultInfocontent_content>div>h5 {
    font-size: .2rem;
   
}
.mconsultInfocontent_content>div>h5>span {
    font-size: .2rem;
    margin: 0;
    padding: 0;
   
}
.mconsultInfocontent_content>section>h2>strong {
    font-size: .28rem;
  
}
.mconsultInfocontent_content>section>h2 {
    font-size: .2rem;
    line-height: .1rem;

}
.mconsultInfocontent_content>section>section>h2>strong {
    font-size: .3rem;
  
}
.mconsultInfocontent_content>section>section>section>section>h2 {
    font-size: .2rem;
  
}
.mconsultInfocontent_content ul{
    font-size: .2rem;
}
blockquote{
    font-size: .2rem;  
}
blockquote>strong{
    font-size: .2rem;
    font-weight: bold; 
    
}
/* 渲染时间 */
.mrenderTimes {
    text-align: center;
    padding-right: 10px;
    margin-right: .2rem;
    position: relative;
    margin-top: .3rem;
}

.mrenderTimes {
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    color: #9A9A9A;
    font-size: .12rem;
}

.mrenderTimes>p:nth-of-type(2) {
    font-size: .12rem;
    margin: 0rem 0.2rem;
}

/* 如有疑问，立即咨询 2019-9-17 */
.mconsultInfoToBtn {
    height: 82px;
    background: #F5F5F5;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px 0;
}

.mconsultInfoToBtn div {
    padding: 10px 20px;
    border: 1px solid #999999;
    border-radius: 20px;
    color: #999999;
    font-size: 17px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.mconsultInfoToBtn i {
    font-size: 24px;
    margin-right: 14px;
}

.mconsultInfoToBtn span {
    font-weight: bold;
}

