/* 标题样式 */

.ctitle_ h3 {
    font-size: 0.24rem;
    font-weight: bold;
    color:#4b5a98;
}
.ctitle_ p{
    font-size: 0.3rem;
    color:#4b5a98;
}

