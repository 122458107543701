.mcustomerLayerAll{
  background-color: #f2f2f2;
 
}

.bannerImg {
  height: 3.3rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("../../assets/customerLawyer/customerLawyer-Wed_01.jpg");
  background-size: 100% 100%;
} 
.bannerImgBtn{
 
  font-size: .24rem;
  border-radius:.28rem;
  background-color: #fff;
  padding:.12rem .48rem;
}
.bannerImgBtn a{
  color:  var(--color);
  text-decoration: none;

}
.bannerImg>img{
  width: .5rem;
  position: absolute;
  top:46%;
  right:34%;
}
/* 共享私人律师服务 */
.mlawyerServiceContiner {
  padding: 0 0.5rem;
  background-color: var(--color);
  margin-top: 0.3rem;
  padding-top: 0.3rem;
} 

/* title start */
 .mlawyerServiceContiner-Cn {
  font-size: 0.3rem;
  font-weight: bold;
  color: #fff;
}

.mlawyerServiceContiner-En {
  font-size: 0.3rem;
  color: #fff;
} 
/* title end */

.mlawyerServiceUl {
  display: flex;
  flex-wrap: wrap;
  font-size: 0.2rem;
  color: #fff;
  margin-top: 0.2rem;
  padding-bottom: 0.2rem;
  justify-content: space-between;
}
.mlawyerServiceUl > li {
  width: 47%;
  margin-bottom: 0.18rem;
}
.mlawyerServiceTitle {
  font-size: 0.26rem;
  font-weight: bold;
  border-bottom: 1px solid #fff;
}
.mlawyerServiceUl p {
  padding-top: 0.1rem;
  font-size: 0.2rem;
} 

/* 为什么选择律政时代 */
.mchooseService {
  padding: 0 0.5rem;
  margin-top: 0.6rem;
}
.mchooseUlAll {
  background-size: 100% 100%;
  padding-top: 2.4rem;
  padding-bottom: 0.2rem;
  margin-bottom: 0.2rem;
}
.mchooseUlAll > li {
  color:  var(--color);
  margin-bottom: 0.18rem;
  padding: 0 0.15rem;
}
.mchooseUl {
  background-image: url("../../assets/m_customerLawyer/m_customerLawyer_06.jpg");
  margin-top: 0.5rem;
}
.mchooseUlMiddle {
  background-image: url("../../assets/m_customerLawyer/m_customerLawyer_09.jpg");
}
.mchooseUlBottom {
  background-image: url("../../assets/m_customerLawyer/m_customerLawyer_11.jpg");
}

.mchooseTitle {
  font-size: 0.24rem;
  font-weight: bold;
  margin-bottom: 0.1rem;
}
.mchooseText {
  font-size: 0.18rem;
  margin-bottom: 0.15rem;
} 

/* 提交表单 19-09-17 wl */
.mlawyerMenuContinerTitle {
  background-color: var(--color);
  margin-bottom: 0.5rem;
  padding: 0.2rem 0.5rem;
}
.mformContiner {
  margin: 0 0.5rem;
}
.mformBox {
  display: flex;
  flex-direction: column;
}
.mformTip {
  display: flex;
  justify-content: center;
  margin-bottom: 0.2rem;
}

.mformTip1 {
  font-size: 0.3rem;
  font-weight: bold;
  color:  var(--color);
  text-align: center;
}

.mformTip2 {
  font-size: 0.26rem;
  font-weight: bold;
  color:  var(--color);
  text-align: center;
}

.mformTip3 {
  font-size: 0.18rem;
  color: var(--color);
  text-align: center;
  padding-top: 0.2rem;
  margin-bottom: 0.8rem;
}

.mformChooseContiner {
  margin-top: 0.3rem;
}
.mformChooseContiner > div {
  display: flex;
  flex-direction: column;
  font-size: 0.22rem;
  margin: 0 0.2rem;
  margin-bottom: 0.3rem;
}

.mformChooseContiner input {
  border-bottom: 2px solid var(--color);
  padding-top: 0.18rem;
  padding-bottom: 0.18rem;
  padding-left: 0.14rem;
  font-size: 0.2rem;
  margin-top: 0.1rem;
  background-color: #f2f4fc;
}
.mformTInfo {
  border-radius: 0.2rem;
  box-shadow: 0 0 0.1rem #c6c4c4;
  background-color: #f2f4fc;
  margin-bottom: 0.4rem;
}
.msubmit-btn {
  width: 100%;
  display: block;
  border-radius: 0.3rem;
  background-color:  var(--color);
  color: white;
  font-size: 0.2rem;
  padding: 0.16rem 0;
  margin-bottom: 0.2rem;

}
.tipText{
 color:  var(--color);
font-size: .26rem;
padding-left: .2rem;
transform: translateY(-.2rem);

}
.mformChooseContiner input::-webkit-input-placeholder{
  color: var(--color);

}
.mformChooseContiner input::-moz-placeholder{
  color:  var(--color);

}
.mformChooseContiner input:-moz-placeholder{
  color: var(--color);

}
.mformChooseContiner input:-ms-input-placeholder{
  color:  var(--color);

}
.mformChooseContiner input::-webkit-input-placeholder{
  color: var(--color)
  ;

} 