
/* 公共样式 */
.mlawyerConsult_conter {
    padding:0.2rem .5rem;
    background-color: #f2f2f2;
    justify-content: space-between;
}
 .mcomcerns_content{
    padding-bottom: .2rem;
 }
.lawyerConsultUl{
    color:#4b5a98 ;
    font-size: .22rem;
    display: flex;
    padding:0.2rem .5rem;
     
}
.lawyerConsultUl>li{
    padding:0 .12rem;
}
.mlawyerConsult_item {
    height: 1.5rem;
    color: #4b5a98;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom:2px solid #4b5a98;
}
.mlawyerConsult_item_img {
    width: 30%;
    height:80%;;
    background-size: cover !important;
}

.mlawyerConsult_item_info {
    box-sizing: border-box;
    padding: .2rem 0;
    width: 60%;
}

.mlawyerConsult_item_info>h2 {
    font-size: .2rem;
}

.mlawyerConsult_time>p {
    font-size: .18rem;
    color: #8A8A8A;
    position: relative;
    line-height: .18rem;
    margin-top: .34rem;
}

.mlawyerConsult_time {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}



.mlawyerConsultContainer_l>button{
    border:1px solid;
    width: 50%;
    border-radius: .2rem;
    padding:.1rem .2rem;
}
.activeStyle{
    background-color: #4b5a98;
    color: #fff;
    border-radius: .14rem;
}