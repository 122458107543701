
.headerNavContiner {
  width: var(--size);
  margin:0 auto;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  height: 133px;
 
}
.headerNavContiner-img {
  width: 20%;
  height: 100%;
  display: flex;
  align-items: center;
}
.headerNavContiner-img>img{
    width:168px;
    height:auto;
}
.headerNavUl {
  display: flex;
  justify-content: space-between;
  width: 80%;
}
.headerNavLi {
  display: flex;
  height: 40px;
  align-items: center;
  text-align: center;
  cursor: pointer;
  color: black;
  padding-bottom: 9px;
}
.curNavLi {
  display: inline-block;
  height: 100%;
  text-decoration: none;
  border-bottom: 3px solid  var(--color);
}
.headerLink {
  font-size: 20px;
  color: black;
  height: 100%;
  text-decoration: none;
  display: block;
}

